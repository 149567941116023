<template>
  <v-container
    style="margin: auto"
    class="blog fill-height justify-center align-center"
  >
    <h1 style="margin-bottom: 3%; margin-top: 3%">
      How to Cite Episodes from a Broadcast Television Series or Streaming
      Series (like Netflix) in APA Format
    </h1>

    <v-row>
      <p>
        Just as you cite a book or magazine in your research papers, you have to
        cite the movies that have contributed to your position. To use the TV
        and movie quotes you’ll find on Pop Mystic in any kind of real research,
        you will need to cite its source. This article is a reference for APA
        style (7th Edition) citations for the quotes from movies and television
        shows you want to use to support your arguments. Much of the information
        presented here is a quick synopsis of the excellent and detailed
        information available at
        <a href="https://www.scribbr.com/category/apa-style">Scribbr.com </a>
      </p>
    </v-row>

    <v-row>
      <v-col cols="12" md="2" lg="2" xl="2" align="center" justify="center">
        <v-img
          class="mx-auto"
          src="../../assets/apa/apa-logo.jpg"
          contain
          max-width="150px"
        ></v-img> </v-col
      ><v-col cols="12" md="10" lg="10" xl="10">
        APA stands for American Psychological Association and is the format most
        commonly used in the social sciences and, well, psychology. Research
        papers in disciplines like sociology or library science are likely
        written in APA format. Though these disciplines are not as likely to
        cite movies and TV shows as those that use MLA format, it does come up.
        For example, imagine writing a paper on the psychology of
        <a href="https://en.wikipedia.org/wiki/Peter_Pan_syndrome"
          >Peter Pan Syndrome</a
        >
        without some great
        <a
          href="https://popmystic.com/searchResults?phrase=he%20grow&title=pan&startYear=1953&endYear=1953"
          >Peter Pan quotes</a
        >
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <h2>Bibliography Citation</h2>
    </v-row>
    <v-row style="margin-bottom: 20px" align="center" justify="center">
      <v-col cols="12" md="2" lg="2" xl="2">
        <v-img
          class="mx-auto"
          src="../../assets/apa/stranger-things-poster.jpg"
          contain
          max-width="200px"
        ></v-img>
      </v-col>
      <v-col cols="10" md="10" lg="10" xl="10">
        Format
        <v-card color="primary">
          <v-card-text>
            Writer last name, Initials. (Writer), & Director last name,
            Initials. (Director). (Year, Month Day). Episode name (Season
            Number, Episode Number) [TV series episode]. In Executive producer
            first initials. Last name (Executive Producer), Series name.
            Production Company1; Production Company2.
          </v-card-text>
        </v-card>
        <br />
        Example
        <v-card color="secondary">
          <v-card-text>
            Duffer, M. (Writer), Duffer, R. (Writer), Bridges, W. (Writer), and
            Levy S. (Director). (2019, July 4). Chapter 3: The Case of the
            Missing Lifeguard (Season 3, Episode 3) [TV series episode]. In D.
            Cohen (Executive Producer), Stranger Things. 21 Laps Entertainment;
            Monkey Massacre; Netflix Studios; Georgia Film, Music and
            Entertainment Office.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <h2>In-Text Citation</h2>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10" md="8" lg="8" xl="8">
        Format
        <v-card color="primary">
          <v-card-text> (Writerlastname et al., year, 00:00:00). </v-card-text>
        </v-card>
        <br />
        Example
        <v-card color="secondary">
          <v-card-text>
            One of the lines, “A trip to China sounds nice” leads Robin to
            suspect that the Chinese restaurant at the food court is involved in
            the Russian spy operation (Duffer et al., 2019, 00:29:42).
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="4">
        <v-img
          class="mx-auto"
          src="../../assets/apa/stranger-trip-to-china.jpg"
          contain
          max-height="300px"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ApaTV",
  data: () => ({
    paragraphs: [
      {
        text: "Just as you cite a book or magazine in your research papers, you have to cite the movies that have contributed to your position. To use the TV and movie quotes you’ll find on <Pop Mystic with link> in any kind of real research, you will need to cite its source. This article is a reference for APA style (7th Edition) citations for the quotes from movies and television shows you want to use to support your arguments. Much of the information presented here is a quick synopsis of the excellent and detailed information available at Scribbr.com <https://www.scribbr.com/category/apa-style/>",
        imageSrc: "src/assets/amazon-button1.png",
      },
    ],
  }),

  components: {},
};
</script>

<style scoped>
.blog {
  font-weight: 600;
  color: black;
}
h2 {
  text-decoration: underline;
  text-align: center;
}
h1 {
  text-align: center;
}
p {
  text-align: center;
}
.theme--light.v-card > .v-card__text,
.theme--light.v-card > .v-card__subtitle {
  color: black;
}
.container {
  max-width: 900px;
}
@media (min-width: 1904px) {
  .container {
    max-width: 900px;
  }
}
@media (min-width: 1264px) {
  .container {
    max-width: 900px;
  }
}
</style>
